import Vue from 'vue'
import Dexie from 'dexie'
import Common from '../common.js'
import _cloneDeep from 'lodash/cloneDeep'
import _uniq from 'lodash/uniq'
import DBCoreMiddleware from '@/assets/js/dexie/middlewares/DBCore'
import GroomyConfig from './GroomyConfig.js'

const DB_STATES = {
    INITIALIZING: 1,
    UPGRADING: 2,
    READY: 3,
    DELETING: 4,
    DELETED: 5
}

const COMPOUND_INDEXES = {
	contact: [
		'[contact_operateur+contact_archive]'
	],
	contract_avenant: [
		'[avenant_contract+avenant_status]',
	],
	contract_config: [
		'[contractconfig_horse+contractconfig_season]',
	],
	contract_config_conditions: [
		'[contractconfigconditions_contractconfig+contractconfigconditions_conditions+contractconfigconditions_typemonte]',
		'[contractconfigconditions_contractconfig+contractconfigconditions_typemonte]',
	],
	contract_config_conditions_articles: [
		'[contractconfigconditionsarticles_contractconfigconditions+contractconfigconditionsarticles_valide]',
	],
	contract_config_type_monte: [
		'[contractconfigtypemonte_contractconfig+contractconfigtypemonte_valide]',
	],
    contract_tiers: [
        '[contracttiers_tiers+contracttiers_contract]',
    ],
    current_account: [
        '[currentaccount_tiers+currentaccount_accountingplan]',
    ],
	horse: [
		'[horse_sexe+horse_inactive]',
		'[horse_stallion+horse_inactive]'
	],
    horse_actes: [
		'[actes_actesstatut+actes_horse]',
		'[actes_actesstatut+actes_horse+actes_actestype]',
		'[actes_horse+actes_actestype]',
		'[actes_actestype+actes_actesstatut]'
    ],
    horse_actes_result: [
		'[result_type+result_acte+result_analyse+result_prelevement+result_horse]'
	],
    horse_categorie_lien: [
        '[lien_horse+lien_categorie]'
    ],
    horse_contact: [
        '[horsecontact_valide+horsecontact_horse]',
        '[horsecontact_valide+horsecontact_horse+horsecontact_contact]',
        '[horsecontact_valide+horsecontact_contact]'
    ],
    horse_media: [
		'[media_horse+media_valide]'
	],
	horse_note: [
		'[note_horse+note_acte]',
	],
	intra_location: [
		'[intralocation_parent+intralocation_parent_type]',
	],
	horse_mouvement: [
		'[mouvement_type+mouvement_horse]',
		'[mouvement_horse+mouvement_date]',
	],
	media: [
		'[media_type+media_fk]',
	],
	qualification: [
		'[qualification_type+qualification_parent]',
	],
	qualification_link: [
		'[qualificationlink_type+qualificationlink_fk]',
		'[qualificationlink_qualification+qualificationlink_type+qualificationlink_fk]',
		'[qualificationlink_qualification+qualificationlink_fk]'
	],
	number_template: [
		'[numberable_id+numberable_field]',
		'[numberable_type+numberable_field+numberable_id]',
		'[numberable_id+numberable_type]',
	],
	planning: [
		'[planning_season+planning_type]',
		'[planning_season+planning_type+planning_lieu]',
		'[planning_season+planning_default+planning_type]',
	],
	planning_horse: [
		'[planninghorse_planning+planninghorse_stallion]',
	],
	planning_schedule_horse: [
		'[schedulehorse_stallion+schedulehorse_schedule]',
		'[schedulehorse_stallion+schedulehorse_schedule+schedulehorse_date]',
	],
	question: [
		'[question_fk+question_type]',
		'[question_type+question_fk+question_code]'
	],
	reponse: [
		'[reponse_type+reponse_data_hidden.id+reponse_data_hidden.type]',
		'[reponse_fk+reponse_type]',
		'[reponse_type+reponse_fk+reponse_question]'
	],
	result: [
		'[result_type+result_acte]'
	],
	season_mare: [
		'[seasonmare_valide+seasonmare_season+seasonmare_horse]',
		'[seasonmare_valide+seasonmare_horse+seasonmare_id]',
		'[seasonmare_horse+seasonmare_id]',
		'[seasonmare_season+seasonmare_horse]',
	],
	season_mare_stallion: [
		'[seasonmarestallion_seasonmare+seasonmarestallion_default]',
		'[seasonmarestallion_seasonmare+seasonmarestallion_horse]',
	],
	tags: [
		'[tags_type+tags_fk]'
	],
	tiers_client_account: [
		'[tiersclientaccount_number+tiersclientaccount_accountingplan]',
	],
	tiers_contact: [
		'[tierscontact_valide+tierscontact_tiers]',
		'[tierscontact_valide+tierscontact_tiers+tierscontact_contact]',
		'[tierscontact_tiers+tierscontact_contact]',
		'[tierscontact_favoris+tierscontact_tiers]',
	],
	tiers_horse: [
		'[tiershorse_tiers+tiershorse_valide]',
		'[tiershorse_horse+tiershorse_valide]',
	],
	tiers_horse_part: [
		'[tiershorsepart_tiershorse+tiershorsepart_propriete+tiershorsepart_pension+tiershorsepart_frais+tiershorsepart_gains]',
	],
	user_config: [
		'[userconfig_user+userconfig_licencekey]',
	],
}

const ADDITIONAL_COLUMNS = {
	contract: {
		transformer: ['ContractTransformer', ''],
		cleaner: 'ContractCleaner',
		columns: [
			'fractions',
			'avenant',
			'paillettes',
			'invoices',
			'porteurs_parts',
			'season_mare_stallion',
			'doses',
			'contract_username'
		],
		api: [
			'avenant',
			'fractions',
			'paillettes',
			'invoices',
			'porteurs_parts',
			'doses',
			'contract_username'
		]
	},
	contract_avenant: {
		transformer: ['ContractAvenantTransformer', ''],
		cleaner: 'ContractAvenantCleaner',
		columns: []
	},
	contract_config: {
		transformer: ['ContractConfigTransformer', ''],
		cleaner: 'ContractConfigCleaner',
		columns: []
	},
	horse: {
		transformer: ['HorseTransformer', ''],
		cleaner: 'HorseCleaner',
		columns: [
			// 'media_filename',
			// 'horse_wholesire',
			// 'race_label',
			// 'robe_label',
			// 'sexe_label',
			// 'pedigree.pere',
			// 'pedigree.mere',
			// 'horse_categorie',
			// 'horse_tiers',
			// 'horse_stallion_label',
			'horse_categories',
			'horse_residence_label',
			'horse_last_mouv_label',
			'horse_intraloc_label',
			'horse_actual_pension',
			'horse_last_vermifuge_date',
			'horse_last_vermifuge_label',
			'horse_last_repro_act',
			'horse_last_echographie_label'
			// 'horse_age',
			// 'country_label',
		]/*,
		api: [
			'horse_residence_label'
		]*/
	},
	horse_note: {
		transformer: ['NoteTransformer', ''],
		cleaner: 'NoteCleaner',
		columns: [
			'note_files_types'
		]
	},
	horse_mouvement: {
		transformer: ['MouvementTransformer', ''],
		cleaner: 'MouvementCleaner',
		columns: []
	},
	horse_actes: {
		transformer: ['ActeTransformer', ''],
		cleaner: 'HorseActesCleaner',
		columns: []
	},
	horse_residence: {
		transformer: ['HorseResidenceTransformer', ''],
		cleaner: 'HorseResidenceCleaner',
		columns: []
	},
	lieu: {
		transformer: ['LieuTransformer', ''],
		cleaner: 'LieuCleaner',
		columns: []
	},
	horse_pension: {
		transformer: ['HorsePensionTransformer', ''],
		cleaner: 'HorsePensionCleaner',
		columns: []
	},
	season: {
		transformer: ['SeasonTransformer', ''],
		cleaner: 'SeasonCleaner',
		columns: [
			'contracts_config_stallion'
		]
	},
	season_mare_stallion: {
		transformer: ['SeasonMareStallionTransformer', ''],
		cleaner: 'SeasonMareStallionCleaner',
		columns: []
	},
	horse_categorie: {
		transformer: ['', ''],
		cleaner: 'HorseCategorieCleaner',
		columns: []
	},
	horse_categorie_lien: {
		transformer: ['', ''],
		cleaner: 'CategorieLienCleaner',
		columns: []
	},
	horse_actes_type: {
		transformer: ['ActeTypeTransformer', ''],
		cleaner: 'HorseActesTypeCleaner',
		columns: []
	},
	horse_actes_type_active: {
		transformer: ['', ''],
		cleaner: 'HorseActesTypeActiveCleaner',
		columns: []
	},
	horse_actes_type_articles: {
		transformer: ['', ''],
		cleaner: 'HorseActesTypeArticlesCleaner',
		columns: []
	},
	articles: {
		transformer: ['ArticleTransformer', ''],
		cleaner: 'ArticleCleaner',
		columns: []
	},
	tiers: {
		transformer: ['TierTransformer', 'light'],
		cleaner: 'TierCleaner',
		columns: [
			'phone_combine'
		]
	},
}

/**
 * Liste des tables sur lesquelles il ne faut pas supprimer les _valide = 0
 */
const INVALID_WHITELIST = [
    'tiers_horse',
    'contract_config_conditions',
    'contract_config_conditions_articles',
    'contract_config_type_monte'
]

const BASE_SCHEMAS = {
    '_sync_temp': '++id,table_name,row_id,columns,state,operation',
	'_files': '&filename,type,state,last_used',
	'_index_buffer': '++id,table_name,api',
	'_cache_data': 'key,value,date'
}

export {
	DB_STATES,
	INVALID_WHITELIST,
	ADDITIONAL_COLUMNS
}

export default class GroomyDB extends Dexie {

	static INSTANCE = null

	static async getInstance(isMaster) {
		if (!GroomyDB.INSTANCE) {
			const licenceKey = await GroomyConfig.getItem('licence_key')
			const tableName = `GROOMY-${licenceKey}-${process.env.VUE_APP_VERSION}`
			GroomyDB.INSTANCE = new GroomyDB(tableName)
			await GroomyDB.INSTANCE.init(isMaster)

			new Common()
		}
		else if (!await GroomyDB.INSTANCE.isOpen()) {
			await GroomyDB.INSTANCE.open()
		}

		return GroomyDB.INSTANCE
	}

	#isMaster = false
	/**
	 * Indique l'état de la BDD
	 */
	state = DB_STATES.INITIALIZING
	licenceKey = null

    /********
     * MAIN *
     ********/
    /**
     * Point d'entrée et d'initialisation de la BDD
     */
    async init(isMaster=true) {
		this.#isMaster = isMaster
		this.licenceKey = await GroomyConfig.getItem('licence_key')
		this.use(DBCoreMiddleware)

		if (this.#isMaster) {
			await this.upgradeIfNeeded()
		}

		const localSchemas = await this.getLocalSchemas()
		const version = await this.getDbVersion()
		this.version(version).stores(localSchemas)

		this.registerEvents()

		try {
			await this.open()
		}
		catch(err) {
			if(err.name === Dexie.errnames.Version) {
				// Fix du bug qui oubliait la version lors d'une suppression ratée
				const currentDbVersion = await this.getDbVersion()
				const version = parseInt(currentDbVersion * 10)
				await this.setDbVersion(version)
				await this.version(version).stores(localSchemas)
				await this.open()
				console.warn('VersionError fixed automatically')
			}
			else {
				throw err
			}
		}

        this.state = DB_STATES.READY
    }

    async delete() {
        this.state = DB_STATES.DELETING
        await super.delete()
		this.state = DB_STATES.DELETED
    }

    /**
     * Vérifie si un schema a changé et upgrade la BDD en cas de besoin
     */
    async upgradeIfNeeded() {
        if (!window.navigator.onLine) {
            return false
        }

        // Récupérer les schemas et upgrade la base de données même si le schema n'a pas changé
		const localSchemas = await this.getLocalSchemas()
		// Si la récupération du schema en ligne échoue, on prend le schema local
		let newIdbSchemas
		try {
			const newSchemas = await this.getServerSchemas()
			newIdbSchemas = _cloneDeep(BASE_SCHEMAS)

			Object.keys(newSchemas).forEach(tableName => {
				// & = Unique operator
				newIdbSchemas[tableName] = '&' + newSchemas[tableName].join(',')
			})
		}
		catch(err) {
			console.error(err)
			return false
		}

		const blacklist_index = ['horse_weatherbys','horse_display_web','tiers_region','tiers_vatcheck','tiers_statut_rcs','tiers_date_creation','tiers_date_cessation','tiers_statut_rne']

		const upgradedTables = []
		Object.keys(newIdbSchemas).forEach(tableName => {
			if(localSchemas[tableName] !== newIdbSchemas[tableName]) {
				// si j'ai un update sur la colonne horse_weatherbys je ne l'ajoute pas aux tables pour éviter une réindexation
				if(localSchemas[tableName] !== undefined && localSchemas[tableName] !== undefined) {
					let a = localSchemas[tableName].split(',')
					let b = newIdbSchemas[tableName].split(',')
					let diff = b.filter(x => !a.includes(x))
					if(diff.length > 0 && diff.some(r=> blacklist_index.includes(r))) {
						return false
					}
				}
				upgradedTables.push(tableName)
			}
		})

		const deletedTables = []
		Object.keys(localSchemas).forEach(tableName => {
			if(!newIdbSchemas[tableName]) {
				deletedTables.push(tableName)
			}
		})

		if (upgradedTables.length === 0 && deletedTables.length === 0) {
			return false
		}

        this.state = DB_STATES.UPGRADING

        await this.upgradeSchemas(newIdbSchemas)

		// Ne pas faire une synchro de 0 si c'est une création de BDD
		if(Object.keys(localSchemas).length > 0) {
			const oldSyncFromScratch = Vue.prototype.$sync.getSyncFromScratch()
			Vue.prototype.$sync.setSyncFromScratch(_uniq(upgradedTables.concat(oldSyncFromScratch)))
		}

        return true
    }

    whenReady() {
        return Common.waitUntil(() => {
            return this.state === DB_STATES.READY
        }, 15000, 50)
    }

    registerEvents() {
		this.on('versionchange', () => {
			console.warn('Fermeture de la connexion GroomyDB pour l\'upgrade du schema')
			// Il faut fermer la connexion pour permettre aux autres onglets de supprimer la BDD
			this.state = DB_STATES.UPGRADING
			this.close()

			// Lorsqu'un autre onglet veut upgrade le schema on reload pour charger ce nouveau schema
			if(this.#isMaster) {
				if(this.state !== DB_STATES.DELETING) {
					this.state = DB_STATES.UPGRADING
					window.location.reload()
				}
			}
		})
	}

    /**********
     * TABLES *
     **********/
    /**
     * @return {Object} {"horse":{"version":1}, "horse_actes":{"version":1} ... }
     */
	async needReset() {
		return await GroomyConfig.getItem('need_reset') || false
    }

    /**
     * Compare les versions locales avec celles du serveur
     * @param {Object} localTables
     * @param {Object} serverTables
     * @return {Array[String]} La liste des noms de table qui ont changées
     */
    diffTables(localTables, serverTables) {
        return Object.keys(serverTables).filter(tableName => {
            const serverTable = serverTables[tableName]
            const localTable = localTables[tableName] || {}
            return serverTable.version != localTable.version
        })
    }

    /**
     * @return {Number} Le numéro de version de la BDD
     */
    async getDbVersion() {
		const key = `storage:${this.name}:verno`
		// Pour la transition vers l'IndexedDB
		if (typeof window !== 'undefined') {
			const storageValue = window.localStorage.getItem(key)
			if (storageValue) {
				await this.setDbVersion(parseInt(storageValue))
				window.localStorage.removeItem(key)
			}
		}

        return await GroomyConfig.getItem(key) || 1
    }

    async setDbVersion(verno) {
		await GroomyConfig.setItem(`storage:${this.name}:verno`, verno)
    }

    async clearConfigTables(tables) {
        let tab_promises = []

        tables.forEach(table => {
            tab_promises.push(
                this.t(table)
                .then(async (t) => {
                    // Si c'est une table avec une langue, on supprimme tout ce qui n'est pas la langue actuelle
                    const langIndex = t.schema.indexes.find(index => (index.name.endsWith('_lang')))
                    if (langIndex) {
                        const lang = await GroomyConfig.getItem('lang') || 'en'
                        return t.where(langIndex.name).notEqual(lang)
                    }
                })
                .then((col) => {
                    if (col) {
                        col.delete()
                    }
                })
            )
        })

        return Promise.all(tab_promises)
    }

    /***********
     * SCHEMAS *
     ***********/
    /**
     * @param {Array[String]} liste avec le nom des tables à récupérer
     * @return {Object} { actes: ['actes_id', 'actes_actetype'] ... }
     */
    async getServerSchemas(tables=[]) {
		const TABLE_STRUCTURES_API = process.env.VUE_APP_BASE_API_URL + "/table/structure/relationships/web"
		const url = `${TABLE_STRUCTURES_API}?licence_key=${this.licenceKey}`

		const response = await Vue.prototype.$request.request_post_api(
			"GroomyDB::getServerSchemas",
			url,
			{ tables },
			false,
			{ autoCatch: false }
		)

        let schemas = {}
        let tableNames = Object.keys(response.retour)
        Object.keys(response.retour).forEach(tableName => {
			schemas[tableName] = []
			const table = response.retour[tableName]
            Object.keys(table).forEach(fieldName => {
                let fieldSchema = fieldName
                const relationship = table[fieldName].relationship

                // Gérer les relations
                if (relationship && tableNames.includes(relationship.table)) {
                    fieldSchema += ` -> ${relationship.table}.${relationship.field}`
				}

				schemas[tableName].push(fieldSchema)
            })
            if (COMPOUND_INDEXES[tableName]) {
				schemas[tableName] = schemas[tableName].concat(COMPOUND_INDEXES[tableName])
			}
			if (ADDITIONAL_COLUMNS[tableName]) {
				schemas[tableName] = schemas[tableName].concat(ADDITIONAL_COLUMNS[tableName].columns)
			}

			// TODO: A supprimer mais implique la resynchronisation depuis 1 de toutes les tables
			schemas[tableName].push('age')
		})

        return schemas
    }

    async setLocalSchemas(tables) {
        await GroomyConfig.setItem(`storage:${this.name}:local_schemas`, tables)
    }

    /**
     * @return {Object} { actes: ['actes_id', 'actes_actetype'] ... }
     */
    async getLocalSchemas() {
		const key = `storage:${this.name}:local_schemas`
		// Pour la transition vers l'IndexedDB
		if (typeof window !== 'undefined') {
			const storageValue = window.localStorage.getItem(key)
			if (storageValue) {
				await this.setLocalSchemas(JSON.parse(storageValue))
				window.localStorage.removeItem(key)
			}
		}
		return await GroomyConfig.getItem(key) || {}
    }

    /**
     * Upgrade les schemas spécifiés en paramètre
     * @param {Object} schemas Schemas à mettre à jour sous la forme { actes: ['actes_id', 'actes_actetype'] ... }
     */
    async upgradeSchemas(newIdbSchemas) {
        if (await this.isOpen()) {
            await this.close()
        }

        // Sauvegarder les nouvelles infos pour le prochain lancement
        const currentDbVersion = await this.getDbVersion()
        await this.setDbVersion(currentDbVersion + 1)
        await this.setLocalSchemas(newIdbSchemas)
    }

    /*********
     * TOOLS *
     *********/
    /**
     *
     * @param {https://dexie.org/docs/TableSchema} idbSchema
     * @return {Array} Liste des champs en string
     */
    idbSchemaToArray(idbSchema) {
        let schema = []
        idbSchema.indexes.forEach(index => {
            schema.push(index.name)
        })
        return schema
    }

    /**
     *
     * @param {String} tableName Nom de la table à récupérer
     * @returns {Dexie.Table} Instance de la table
     */
    async t(tableName) {
		// Si la BDD se ferme en cas d'inactivité
		if(this.state === DB_STATES.READY) {
			const isOpen = await this.isOpen()
			if(!isOpen) {
				await this.open()
			}
		}
		else {
			await this.whenReady()
		}

        return this.table(tableName)
    }
}
