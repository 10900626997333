/*
METAS :
	accessRights: String => Code de droit d'accès (par ex, "MVP", ou "TRIGG")
	canDisplayNavMobile: Boolean[default=true] => Indique si le header nav mobile peut s'afficher sur le composant
	recurAccessRights: Boolean[default=false] => Indique que le accessRights prend également effet sur les routes enfants
	noLoggedIn: Boolean[default=false] => Indique que cette route est accessible uniquement sans authentification
	canAccessLoggedIn: Boolean[default=false] => Indique que la route est accessible qu'on soit connecté ou non
	needLoading: Boolean[default={noLoggedIn ? false : true}] => Indique que le route a besoin de passer par le chargement
*/

export default {
	routes: [
		{
			path: "/",
			name: "Home",
			component: () => import("@/views/Home"),
			meta: {
				noLoggedIn: true,
				onlineOnly: true,
			}
		},
		{
			path: "/loading",
			name: "Loading",
			component: () => import("@/views/LoadingScreen"),
			props: true
		},
		{
			path: "/cards",
			name: "cards",
			component: () => import("@/components/Cards"),
			meta: { 
				accessRights: "MVP"
			}
		},
		{
			path: "/horse",
			name: "horseListe",
			component: () => import("@/views/HorseListe"),
			meta: { 
				accessRights: "MVP"
			}
		},
		{
			path: "/horse_archived/",
			name: "horseArchivedList",
			component: () => import("@/views/HorseArchivedList"),
			meta: {
				accessRights: "MVP",
			}
		},
		{
			path: "/horse_statistiques",
			name: "horseStatistiques",
			component: () => import("@/views/HorseStatistiques"),
			meta: {
				accessRights: "FACT"
			},
			children: [
				{
					path: "pension_liste/",
					name: "pensionListe",
					component: () => import("@/components/Horse/PensionListe"),
					meta: {
						accessRights: "FACT"
					}
				},
			]
		},
		{
			path: "/horse_search",
			name: "horseSearch",
			component: () => import("@/views/HorseSearch"),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "/qualif",
					name: "SearchQualification",
					component: () => import("@/components/Qualification/SearchQualification"),
					props: () => ({
						qualif_type: 'horse'
					}),
					meta: { 
						accessRights: "TIERS|FACT"
					}
				},
				{
					path: "/horse_without_act",
					name: "horseWithoutAct",
					component: () => import("@/components/Horse/HorseWithoutAct"),
					meta: { 
						accessRights: "MVP"
					}
				},
				{
					path: "/horse_without_pension",
					name: "horseWithoutPension",
					component: () => import("@/components/Horse/HorseWithoutPension"),
					meta: { 
						accessRights: "MVP"
					}
				}
			]
		},
		{
        	path: '/simulateur_croisement',
        	name: 'simulateurCroisement',
        	component: () => import('@/views/SimulateurCroisement'),
			meta: {
				accessRights: "REPRO|SAILL"
			}
        },
		{
        	path: '/juments_non_suivies',
        	name: 'jumentsNonSuivies',
        	component: () => import('@/views/JumentsNonSuivies'),
			meta: {
				accessRights: "REPRO|SAILL"
			}
        },
		{
			path: "/horse/:horse_id",
			name: "horseFiche",
			component: () => import("@/views/HorseFiche"),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "horse_informations/",
					name: "HorseFicheInfo",
					component: () => import("@/components/InformationsHorse"),
					props: true,
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "monte/",
					name: "ContractConfigStallionList",
					component: () => import("@/components/Contract/ContractConfigStallionList"),
					props: true,
					meta: {
						accessRights: "SAILL"
					}
				},
				{
                	path: "charge/",
                    name: "HorseCharge",
                    component: () => import("@/components/Horse/HorseCharge"),
					props: true,
					meta: {
						onlineOnly: true
					}
                },
				{
					path: "contract/",
					name: "ContractStallionList",
					component: () => import("@/components/Contract/ContractStallionList"),
					props: true,
					meta: {
						accessRights: "SAILL"
					}
				},
				{
					path: "contract_online/",
					name: "ContractStallionList",
					component: () => import("@/components/Contract/ContractStallionListOnline"),
					props: true,
					meta: {
						accessRights: "SAILL"
					}
				},
				{
					path: "monte_jument/",
					name: "ContractMareList",
					component: () => import("@/components/Contract/ContractMareList"),
					props: true,
					meta: {
						accessRights: "SAILL"
					}
                },
                {
					path: "syndication/",
					name: "StallionSyndication",
					component: () => import("@/components/Syndication/StallionSyndication.vue"),
					props: true,
					meta: {
						accessRights: "SAILL"
					}
				},
                {
					path: "historique_syndication/",
					name: "StallionHistoriqueSyndication",
					component: () => import("@/components/Syndication/StallionHistoriqueSyndication.vue"),
					props: true,
					meta: {
						accessRights: "SAILL"
					}
				},
				{
					path: "invoices/",
					name: "HorseFicheInvoice",
					component: () => import("@/components/Invoice/List"),
					props: true,
					meta: {
						accessRights: "FACT",
						onlineOnly: true,
					}
				},
				{
					path: "horse_mouvements",
					name: "HorseFicheMouvements",
					component: () => import("@/components/HistoriqueMouvements"),
					props: true,
					meta: {
						accessRights: "MVP",
						recurAccessRights: true
					}
				},
				{
					path: "deplacement_interne",
					name: "HorseFicheDeplacementInterne",
					component: () => import("@/components/HistoriqueInterne"),
					props: true,
					meta: {
						accessRights: "MVP",
						recurAccessRights: true
					}
				},
				{
					path: "horse_actes/",
					name: "HorseFicheActes",
					component: () => import("@/components/Horse/HorseActes"),
					props: (route) => ({
						horse_id: parseInt(route.params.horse_id),
						acte_statut: 1
					}),
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "horse_all_actes/",
					name: "HorseFicheAllActes",
					component: () => import("@/components/Horse/HorseAllActes"),
					props: (route) => ({
						horse_id: parseInt(route.params.horse_id)
					}),
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "horse_notes/",
					name: "HorseFicheNotes",
					component: () => import("GroomyRoot/components/Notes/List"),
					props: true,
					meta: {
						accessRights: "NOTE"
					}
				},
				{
					path: "horse_planning/",
					name: "HorseFichePlanning",
					component: () => import("@/components/Horse/HorseActes"),
					props: (route) => ({
						horse_id: parseInt(route.params.horse_id),
						acte_statut: 0
					}),
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "horse_ordonnances/",
					name: "HorseFicheOrdonnances",
					component: () => import("@/components/OrdonnancesHorse"),
					props: true,
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "tiers/",
					name: "HorseTiers",
					component: () => import("@/components/Horse/Tiers"),
					props: true,
					meta: {
						accessRights: "TIERS|FACT|REPRO"
					}
				},
				{
					path: "tiers_secondaires/",
					name: "HorseTiersSecondaires",
					component: () => import("@/components/Horse/HorseTiersSecondaires"),
					props: true,
					meta: {
						accessRights: "TIERS|FACT|REPRO"
					}
				},
				{
					path: "contact/",
					name: "HorseContact",
					component: () => import("@/components/Horse/Contact"),
					props: true,
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "pension/",
					name: "HorsePension",
					component: () => import("@/components/Horse/Pension"),
					props: true,
					meta: {
						accessRights: "FACT",
						onlineOnly: true,
					}
				},
				{
					path: "gynecologie/",
					name: "HorseGynecologie",
					component: () => import("@/components/Gynecologie/HorseSummary"),
					// props: false, // Pour ne pas envoyer horse_id au format Number
					meta: {
						accessRights: "REPRO",
						onlineOnly: false,
					}
				},
				{
					path: "monte/",
					name: "HorseMonte",
					component: () => import("@/components/Gynecologie/Monte"),
					meta: {
						accessRights: "MVP"
					},
					children: [
						{
							path: "bilans/",
							name: "HorseBilanSaison",
							component: () => import("@/components/Gynecologie/HorseBilanSaison"),
							meta: {
								accessRights: "MVP"
							},
							children: [
								{
									path: "stat_fertilite/",
									name: "HorseStatFertilite",
									component: () => import("@/components/Gynecologie/HorseStatFertilite"),
									props: true, 
									meta: {
										accessRights: "MVP"
									},
								}
							]
						},
					]
				},
				{
					path: "qualifications/",
					name: "HorseQualificationFiche",
					component: () => import("@/components/Qualification/QualificationFiche"),
					props: (route) => ({
						qualificationlink_type: 'horse',
						qualificationlink_fk: route.params.horse_id
					}),
					meta: {
						accessRights: "FACT"
					}
				},
				{
					path: "horse_product/",
					name: "HorseProduct",
					component: () => import("@/components/Horse/HorseProduct"),
					props: true,
					meta: {
						accessRights: "REPRO|SAILL"
					}
				},
				// {
				// 	path: "courrier/",
				// 	name: "horseCourrierPerso",
				// 	component: () => import("@/components/Courrier/Horse"),
				// 	props: true
				// },
				{
					path: "sanitaire",
					name: "horseSanitaire",
					component: () => import("@/components/Sanitaire/List"),
					props: true,
					// meta: {
					// 	accessRights: "SANI"
					// }
				},
				{
					path: "envoi_sanitaire",
					name: "horseTeletransmissionSire",
					component: () => import("@/components/Sanitaire/EnvoiJument"),
					props: true,
					// meta: {
					// 	accessRights: "SANI"
					// }
				},
				{
					path: "stock_distant/",
					name: "StallionStockDistant",
					component: () => import("@/components/Monte/StallionStockDistant"),
					props: true,
					meta: {
						accessRights: "SEMENCE"
					}
				},
				{
					path: "stock_local/",
					name: "StallionStockLocal",
					component: () => import("@/components/Monte/StallionStockLocal"),
					props: true,
					meta: {
						accessRights: "SEMENCE"
					}
				},
				{
					path: "stat_saillies/",
					name: "StatSaillieStallion",
					component: () => import("@/components/Monte/StatSaillieStallion"),
					props: true,
				},
                {
                	path: "croissance/",
                    name: "HorseSuiviCroissance",
                    component: () => import("@/components/Horse/HorseCroissance"),
					props: true,
					meta: {
						onlineOnly: true
					}
                },
                {
                	path: "croissance/cr=:croissance_id?",
                    name: "AddHorseCroissance",
                    component: () => import("@/components/Horse/AddHorseCroissance"),
					props: true,
					meta: {
						onlineOnly: true
					}
                },
                {
                	path: "croissance/compare",
                    name: "HorseCroissanceGraph",
                    component: () => import("@/components/Horse/HorseCroissanceGraph"),
					props: true,
					meta: {
						onlineOnly: true
					}
                },
                {
                	path: "pax/",
                    name: "HorsePax",
                    component: () => import("@/components/Horse/Pax"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "CAPAX"
					}
                },
                {
                	path: "pax_pays",
                	name: "HorsePaxPays",
                    component: () => import("@/components/Horse/PaxPays"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "CAPAX"
					}
                },
				{
                	path: "temperature/",
                    name: "HorseSuiviTemperature",
                    component: () => import("@/components/Horse/HorseTemperature"),
					props: true,
					meta: {
						onlineOnly: true
					}
                },
				{
                	path: "temperature/tp=:temperature_id?",
                    name: "AddHorseTemperature",
                    component: () => import("@/components/Horse/AddHorseTemperature"),
					props: true,
					meta: {
						onlineOnly: true
					}
                },
				{
					path: "courrier/",
					name: "HorseCourrierPerso",
					component: () => import("@/components/Horse/Courrier"),
					props: true
				},
				{
					path: "import_video/",
					name: "ImportVideo",
					component: () => import("@/components/Horse/ImportVideo"),
					props: true
				},
				{
					path: "web_content/",
					name: "HorseWebContent",
					component: () => import("@/components/WebContent"),
					props: (route) => ({
						id: route.params.horse_id,
						type: 'horse'
					}),
					meta: {
						onlineOnly: true,
						accessRights: "COMWEB"
					}
				},
				{
					path: "charge_supplier",
					name: "HorseChargeSupplier",
					component: () => import("@/components/InvoiceSupplier/HorseChargeSupplier"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FOUROCR"
					}
				},
				{
					path: "congelation_settings",
					name: "StallionCongelation",
					component: () => import("@/components/Congelation/StallionSettings"),
					props: true,
					meta: {
						accessRights: "CONGEL"
					}
				}
			]
		},
		{
			path: "/horse_ajout/:first_login?",
			name: "horseAjout",
			component: () => import("@/views/HorseAjout"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			}
		},
		{
			path: "/horse_creation",
			name: "horseCreation",
			component: () => import("@/views/HorseCreation"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			}
		},
		{
			path: "/horse_merge/h_id=:horse_id",
			name: "mergeHorse",
			component: () => import("@/views/MergeHorse"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true
			},
		},
		{
			path: "/horse_ajout_transpondeur",
			name: "HorseAjoutByTranspondeur",
			component: () => import("@/views/HorseAjoutByTranspondeur"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/acte_liste/t=:tab?/d=:date?",
			name: "acteListe",
			component: () => import("@/views/ActeListe"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/acte_liste_online/t=:tab?/d=:date?",
			name: "acteListeOnline",
			component: () => import("@/views/ActeListeBeta"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/planning/t=:tab?/d=:date?",
			name: "planning",
			component: () => import("@/views/Planning"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/calendar",
			name: "calendar",
			component: () => import("@/views/Calendar"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/acte_ajout/h_id=:horse_id?/a_id=:acte_id?/f=:from?/s=:state?",
			name: "acteAjout",
			component: () => import("@/views/ActManagement"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			}
		},
		{
			path: "/mouvement_liste/",
			name: "mouvementListe",
			component: () => import("@/views/MouvementListe"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/mouvement/residence",
			name: "mouvementResidence",
			component: () => import("@/views/MouvementResidence"),
			meta: {
				accessRights: "MVP"
			},
			props: true
		},
		{
			path: "/localisation_residence",
			name: "localisationResidence",
			component: () => import("@/views/LocalisationResidence"),
			meta: {
				accessRights: "MVP"
			},
			props: true
		},
		{
			path: "/mouvement_ajout/h_id=:horse_id?/m_id=:mouvement_id?/f=:from?/f_mv=:first_mouv?/m_type=:type?",
			name: "mouvementAjout",
			component: () => import("@/views/MouvementManagement"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			},
			children: [
				{
					path: "mouvement_form/",
					name: "WriteMouvement",
					component: () => import("@/components/WriteMouvement")
				}
			]
		},
		{
			path: "/mediatheque/",
			name: "mediatheque",
			component: () => import("@/views/Mediatheque"),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "ordonnance_liste/",
					name: "ordonnanceListe",
					component: () => import("@/components/Documents/OrdonnanceListe"),
					props: true
				},
				{
					path: "registry_history/",
					name: "registryHistory",
					component: () => import("@/components/Documents/RegistreHistory"),
					props: true,
					meta: {
						onlineOnly: true,
					},
				},
				{
					path: "notes_liste/",
					name: "noteListe",
					component: () => import("@/components/Documents/NoteListe"),
					props: true,
					meta: {
						accessRights: "NOTE",
					},
				}
			]
		},
		{
			path: "/ordonnance_ajout/h_id=:horse_id?/f=:from?",
			name: "ordonnanceAjout",
			component: () => import("@/views/OrdonnanceManagement"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			},
			children: [
				{
					path: "ordonnance_form/",
					name: "ordonnanceForm",
					component: () => import("@/components/Documents/OrdonnanceForm"),
					props: true
				},
			]
		},
		{
			path: "/ordonnance_edit/document_id=:document_id/f=:from?",
			name: "ordonnanceEdit",
			component: () => import("@/views/OrdonnanceManagement"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			}
		},

        /* COMPTE */
        {
			path: "/addressed_invoices",
			name: "addressedInvoices",
			component: () => import("@/views/InvoicesAddressed"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true,
            },
            children: [
				{
					path: "check_email/",
					name: "CheckEmailIA",
                    component: () => import("@/views/EmailValidation"),
					meta: {
						onlineOnly: true,
						canDisplayNavMobile: false
					}
                }
            ]
        },
        {
			path: "/addressed_payments",
			name: "addressedPayments",
			component: () => import("@/views/PaymentsAddressed"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true,
            },
            children: [
				{
					path: "check_email/",
					name: "CheckEmailPA",
					component: () => import("@/views/EmailValidation"),
					meta: {
						onlineOnly: true,
						canDisplayNavMobile: false
					}
                }
            ]
		},
		{
			path: "/switch_licence",
			name: "switchLicence",
			component: () => import("@/views/LicenceSwitch"),
			meta: {
				canAccessLoggedIn: true,
				needLoading: false,
				canDisplayNavMobile: false
			},
			children: [
				{
					path: "check_email_ls/em=:email?/tk=:token?/sg=:sign?/ui=:user_ud?/ils=:is_licence_switch?/tlc=:temp_licence?",
					name: "CheckEmailLS",
					component: () => import("@/views/EmailValidation"),
					meta: {
						canAccessLoggedIn: true,
						needLoading: false
					}
                }
            ]
		},
		{
			path: "/display_settings",
			name: "displaySettings",
			component: () => import("@/views/DisplaySettings"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true,
			}
		},
		{
			path: "/notification_handler/:notif_id/:action/:params?",
			name: "notificationHandler",
			component: () => import("@/views/NotificationHandler"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/recherche",
			name: "recherche",
			component: () => import("@/views/Recherche"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/compte_creation",
			name: "compteCreation",
			component: () => import("@/views/CompteCreation"),
			props: (route) => ({
				cp: route.query.cp,
				redirect: route.query.redirect,
				email: route.query.mail,
				aqs: route.query.aqs
			}),
			meta: {
				noLoggedIn: true,
				onlineOnly: true,
			}
		},
		{
			path: "/compte_creation_salon",
			name: "compteCreationSalon",
			component: () => import("@/views/CompteCreationSalon"),
			props: (route) => ({
				cp: route.query.cp,
				redirect: route.query.redirect,
				email: route.query.mail,
				aqs: route.query.aqs
			}),
			meta: {
				noLoggedIn: true,
				onlineOnly: true,
			}
		},
		{
			path: "/compte_validation",
			name: "compteValidation",
			component: () => import("@/views/CompteValidation"),
			props: (route) => ({
				redirect: route.query.redirect
			}),
			meta: {
				noLoggedIn: true,
				canAccessLoggedIn: true,
				onlineOnly: true,
			}
		},
		{
			path: "/reset_password",
			name: "resetPassword",
			component: () => import("@/views/ResetPassword"),
			meta: {
				noLoggedIn: true,
				onlineOnly: true,
			}
		},
		{
			path: "/update_password/:token/:email",
			name: "PasswordUpdate",
			component: () => import("@/views/PasswordUpdate"),
			meta: {
				noLoggedIn: true,
				canAccessLoggedIn: true,
				onlineOnly: true,
			}
        },
        {
			path: "/valid_email/:token/:signature/:email/:userid",
			name: "EmailValidation",
			component: () => import("@/views/EmailValidation"),
			meta: {
				noLoggedIn: true,
				canAccessLoggedIn: true,
				canDisplayNavMobile: false,
				onlineOnly: true,
			}
		},
		{
			path: "/autolog",
			name: "autolog",
			component: () => import("@/views/AutoLogAdmin"),
			meta: {
				accessRights: "ADMN",
				onlineOnly: true,
			}
		},

		/* COMPTABILITE */
		{
			path: "/accounting/",
			name: "accountingListe",
			meta: {
				accessRights: "FACT|FOUROCR",
				recurAccessRights: true,
				onlineOnly: true,
			},
			component: () => import("@/views/AccountingListe"),
		},
		{
			path: "/accounting_archived/",
			name: "accountingArchivedList",
			component: () => import("@/views/AccountingArchivedList"),
			meta: {
				accessRights: "FACT|FOUROCR",
				onlineOnly: true
			}
		},
		{
			path: "/accounting/:accountingplan_id",
			name: "accountingFiche",
			meta: {
				accessRights: "FACT|FOUROCR",
				recurAccessRights: true,
				onlineOnly: true,
			},
			component: () => import("@/views/AccountingFiche"),
			children: [
				{
					path: "informations/",
					name: "AccountingInformations",
					component: () => import("@/components/Accounting/AccountingInformations"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "accounting_account/",
					name: "AccountingFicheAccountingAccount",
					component: () => import("@/components/Accounting/AccountingAccount"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "accounting_payment_account/",
					name: "AccountingFicheAccountingPaymentAccount",
					component: () => import("@/components/Accounting/AccountingPaymentAccount"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "model/",
					name: "AccountingModel",
					component: () => import("@/components/Model/Model"),
					props: (route) => ({
						modelable_id: route.params.accountingplan_id,
						modelable_types: ['accounting_plan', 'extrait_compte']
					}),
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "model/edit/:model_id",
					name: "EditModel",
					component: () => import("@/components/Model/Edit"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "articles/",
					name: "AccountingFicheArticles",
					component: () => import("@/components/Accounting/Article"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "actes_type_articles/",
					name: "AccountingFicheActesTypeArticles",
					component: () => import("@/components/Accounting/ActesTypeArticles"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "pension/",
					name: "AccountingFichePension",
					component: () => import("@/components/Accounting/Pension"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "check_configuration/",
					name: "AccountingRemiseChequeConfig",
					component: () => import("@/components/Accounting/CheckConfig"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "export_config/",
					name: "ExportConfig",
					component: () => import("@/components/Accounting/AccountingExportConfig"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "accounting_config/",
					name: "AccountingConfig",
					component: () => import("@/components/Accounting/AccountingConfig"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
			]
		},

		/* INVOICES */
		{
			path: "/invoice",
			name: "invoice",
			component: () => import("@/views/Invoice"),
			meta: {
				accessRights: "FACT",
				recurAccessRights: true,
				onlineOnly: true,
			},
			children: [
				{
					path: "list/",
					name: "invoiceList",
					component: () => import("@/components/Invoice"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "bons_old/",
					name: "invoiceBonsOld",
					component: () => import("@/components/Invoice/Bons"),
					props: true,
					meta: {
						onlineOnly: true,
					},
				},
				{
					path: "bons/",
					name: "invoiceBons",
					component: () => import("@/components/Invoice/Bonsv3"),
					props: true,
					meta: {
						onlineOnly: true,
					},
				},
				{
					path: "a_valider/",
					name: "invoiceAValider",
					component: () => import("@/components/Invoice/AValider"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "unpaid/",
					name: "impayesList",
					component: () => import("@/components/Impayes"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "tiers_unpaid_list/",
					name: "tiersImpayesList",
					component: () => import("@/components/Invoice/TiersListImpayes"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "control_properties/",
					name: "controlProperties",
					component: () => import("@/components/Invoice/ControlProperties"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "results/",
					name: "InvoiceResults",
					component: () => import("@/components/Invoice/Bilan"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "monte/",
					name: "aValiderMonte",
					component: () => import("@/components/Invoice/Monte/Router"),
					props: true,
					meta: {
						accessRights: "SAILL",
						onlineOnly: true,
					},
					children: [
						{
							path: "signature/",
							name: "aValiderSignature",
							component: () => import("@/components/Invoice/Monte/AValiderSignature"),
							props: true
						},
						{
							path: "saillie/",
							name: "aValiderSaillie",
							component: () => import("@/components/Invoice/Monte/AValiderSaillie"),
							props: true
						},
						{
							path: "octobre/",
							name: "aValiderOctobre",
							component: () => import("@/components/Invoice/Monte/AValiderOctobre"),
							props: true
						},
						{
							path: "poulain/",
							name: "aValiderPoulain",
							component: () => import("@/components/Invoice/Monte/AValiderPoulain"),
							props: true
						}
					]
				},
				{
					path: "details/order/:invoicedetails_ids",
					name: "invoiceDetailOrder",
					component: () => import("@/components/Invoice/Detail"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "details_v2/order/:invoicedetails_ids",
					name: "invoiceDetailOrderv2",
					component: () => import("@/components/Invoice/Detailv2"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "course_articles",
					name: "articleCourse",
					component: () => import("@/components/Invoice/ArticlesCourse"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				}
			]
		},

		{
			path: "/invoice_supplier",
			name: "invoiceSupplier",
			component: () => import("@/views/InvoiceSupplier"),
			meta: {
				accessRights: "FOUROCR",
				recurAccessRights: true,
				onlineOnly: true,
			},
			children: [
				{
					path: "control",
					name: "invoiceSupplierControl",
					component: () => import("@/components/InvoiceSupplier/Control"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "checked",
					name: "invoiceSupplierChecked",
					component: () => import("@/components/InvoiceSupplier/Checked"),
					props: true,
					meta: {
						onlineOnly: true,
					},
				},
				{
					path: "payment",
					name: "invoiceSupplierPayment",
					component: () => import("@/components/InvoiceSupplier/Payment"),
					props: true,
					meta: {
						onlineOnly: true,
					},
				}
			]
		},

		{
			path: "/export_deb_des",
			name: "exportDEBDES",
			component: () => import("@/views/ExportDEBDES"),
			meta: {
				accessRights: "DEBDES",
				recurAccessRights: true
			},
			children: [
				{
					path: "deb/",
					name: "exportDEB",
					component: () => import("@/components/Invoice/ExportDEB"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "des/",
					name: "exportDES",
					component: () => import("@/components/Invoice/ExportDES"),
					props: true,
					meta: {
						onlineOnly: true,
					},
				},
			]
		},

		{
			path: "/bank_flow",
			name: "bankFlow",
			component: () => import("@/views/BankFlow"),
			meta: {
				accessRights: "FACT|FOUROCR",
				recurAccessRights: true
			},
		},

		/* BILANS */
		{
			path: "/bilans",
			name: "bilans",
			component: () => import("@/views/Bilans"),
			meta: {
				accessRights: "REPRO",
				recurAccessRights: true
			},
			children: [
				{
					path: "jumenterie/",
					name: "etatJumenterie",
					component: () => import("@/components/Gynecologie/Jumenterie"),
					props: true
				},
				{
					path: "croisements/",
					name: "croisements",
					component: () => import("@/components/Gynecologie/Croisements"),
					props: true
				},
				{
					path: "statistiques/",
					name: "statistiquesRepro",
					component: () => import("@/components/Gynecologie/Statistiques"),
					props: true
				},
				{
					path: "fertilite/",
					name: "fertiliteRepro",
					component: () => import("@/components/Gynecologie/Fertilite"),
					props: true
				}
			]
		},
		{
			path: "/bilans/monte/",
			name: "saisonMonte",
			component: () => import("@/views/BilanMonte"),
			meta: {
				accessRights: "SAILL",
				recurAccessRights: true
			},
		},

		/* PAYMENT */
		{
			path: "/payments",
			name: "payments",
			component: () => import("@/views/Payments"),
			meta: {
				accessRights: "FACT",
				recurAccessRights: true,
				onlineOnly: true,
			},
			children: [
				{
					path: "list/",
					name: "paymentList",
					component: () => import("@/components/Payment/List"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "check_deposit/",
					name: "checkDeposit",
					component: () => import("@/components/Payment/CheckDeposit"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "regul/",
					name: "regulBancaire",
					component: () => import("@/components/Payment/Regul"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "add_from_bank/",
					name: "addPaymentFromBank",
					component: () => import("@/components/Payment/AddPaymentFromBank"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				}
			]
		},

		/* TIERS */
		{
			path: "/tiers/",
			name: "tiersListe",
			component: () => import("@/views/TiersListe"),
			meta: {
				accessRights: "TIERS|FACT|REPRO",
			}
		},
		{
			path: "/tiers_online/",
			name: "tiersListeOnline",
			component: () => import("@/views/TiersListeOnline"),
			meta: {
				accessRights: "TIERS|FACT|REPRO",
			}
		},
		{
			path: "/tiers_ajout/",
			name: "tiersAjout",
			component: () => import("@/views/TiersAjout"),
			meta: {
				accessRights: "TIERS|FACT|REPRO",
			}
		},
		{
			path: "/tiers_archived/",
			name: "tiersArchivedListe",
			component: () => import("@/views/TiersArchivedListe"),
			meta: {
				accessRights: "TIERS|FACT|REPRO",
			}
		},
		{
			path: "/tiers/search",
			name: "tiersSearch",
			component: () => import("@/views/TiersSearch"),
			meta: {
				accessRights: "TIERS|FACT|REPRO",
			}
		},
		{
			path: "/contacts/",
			name: "contactList",
			component: () => import("@/views/ContactList"),
			meta: {
				accessRights: "MVP",
			}
		},

		{
			path: "/contact/:contact_id",
			name: "contactFiche",
			component: () => import("@/views/ContactFiche"),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "coordonnees/",
					name: "contactCoordonnees",
					component: () => import("@/components/Contact/Coordonnees"),
					props: true
				},
				{
					path: "contact_tiers/",
					name: "contactTiers",
					component: () => import("@/components/Contact/Tiers"),
					props: true
				},
				{
					path: "contact_horse/",
					name: "contactHorse",
					component: () => import("@/components/Contact/Horse"),
					props: true
				}
			]
		},
		{
			path: "/contacts_archived/",
			name: "contactArchivedList",
			component: () => import("@/views/ContactArchivedList"),
			meta: {
				accessRights: "MVP",
			}
		},
		{
			path: "/tiers/:tiers_id",
			name: "tiersFiche",
			component: () => import("@/views/TiersFiche"),
			meta: {
				accessRights: "TIERS|FACT|REPRO",
			},
			children: [
				{
					path: "contact/",
					name: "tiersContact",
					component: () => import("@/components/Tiers/Contact"),
					props: true,
				},
				{
					path: "dashboard/",
					name: "tiersTableauBord",
					component: () => import("@/components/Tiers/TableauBord"),
					props: true,
				},
				{
					path: "coordonnees/",
					name: "tiersCoordonnees",
					component: () => import("@/components/Tiers/Coordonnees"),
					props: true,
				},
				{
					path: "legal/",
					name: "tiersJuridique",
					component: () => import("@/components/Tiers/Juridique"),
					props: true,
				},
				{
					path: "horse/",
					name: "tiersHorse",
					component: () => import("@/components/Tiers/HorseOnline"),
					props: true,
				},
				// {
				// 	path: "horse_online/",
				// 	name: "tiersHorseOnline",
				// 	component: () => import("@/components/Tiers/HorseOnline"),
				// 	props: true,
				// },
				{
					path: "horse_exterieur/",
					name: "tiersHorseExterieur",
					component: () => import("@/components/Tiers/HorseExterieur"),
					props: true,
				},
				{
					path: "horse_secondaires/",
					name: "tiersHorseSecondaires",
					component: () => import("@/components/Tiers/TiersHorseSecondaires"),
					props: true,
				},
				{
					path: "monte/",
					name: "tiersMonte",
					component: () => import("@/components/Tiers/Monte"),
					props: true,
					children: [
						{
							path: "contrats/",
							name: "tiersContracts",
							component: () => import("@/components/Contract/ContractTiersList"),
							props: true
						},
						{
							path: "lieu_monte/",
							name: "tiersLieuMonte",
							component: () => import("@/components/Contract/ContractTiersLieuMonte")
						},
						{
							path: "reservations/",
							name: "tiersMonteReservation",
							component: () => import("@/components/Tiers/MonteReservation"),
							props: (route) => ({
								tiers_id: parseInt(route.params.tiers_id)
							}),
						},
						{
							path: "courtier/",
							name: "tiersCourtier",
							component: () => import("@/components/Contract/ContractTiersCourtier")
						},
						{
							path: "semence/",
							name: "tiersSemence",
							component: () => import("@/components/Monte/StockTiers"),
							props: (route) => ({
								tiers_id: parseInt(route.params.tiers_id)
							}),
							meta: {
								accessRights: "SEMENCE",
							},
						},
						{
							path: "syndication/",
							name: "TirersSyndication",
							component: () => import("@/components/Tiers/Syndication"),
							props: (route) => ({
								tiers_id: parseInt(route.params.tiers_id)
							}),
						},
					]
				},
				{
					path: "invoices/",
					name: "tiersInvoice",
					component: () => import("@/components/Tiers/Invoice"),
					props: true,
					meta: {
						accessRights: "FACT",
					},
				},
				{
					path: "bons/",
					name: "tiersBons",
					component: () => import("@/components/Tiers/Bons"),
					props: true,
					meta: {
						accessRights: "FACT",
					},
				},
				{
					path: "a_valider/",
					name: "tiersAValider",
					component: () => import("@/components/Tiers/AValider"),
					props: true,
					meta: {
						accessRights: "FACT",
					},
				},
				{
					path: "details/order/:invoicedetails_ids",
					name: "invoiceDetailOrderTiers",
					component: () => import("@/components/Invoice/Detail"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "details_v2/order/:invoicedetails_ids",
					name: "invoiceDetailOrderTiersv2",
					component: () => import("@/components/Invoice/Detailv2"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "payments/",
					name: "tiersPayment",
					component: () => import("@/components/Tiers/Payment"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "parameters/",
					name: "tiersParameters",
					component: () => import("@/components/Tiers/Parameters"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "qualifications/",
					name: "tiersQualifications",
					component: () => import("@/components/Qualification/QualificationFiche"),
					props: (route) => ({
						qualificationlink_type: 'tiers',
						qualificationlink_fk: route.params.tiers_id
					}),
					meta: {
						accessRights: "TIERS|FACT"
					}
				},
				{
					path: "suppliers_invoices/",
					name: "tiersSuppliersInvoices",
					component: () => import("@/components/Tiers/SupplierInvoice"),
					props: (route) => ({
						tiers_id: parseInt(route.params.tiers_id)
					}),
					meta: {
						onlineOnly: true,
						accessRights: "FACT"
					}
				},
				{
					path: "invoice_supplier/control",
					name: "tiersInvoiceSupplierControl",
					component: () => import("@/components/InvoiceSupplier/TiersControl"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FOUROCR"
					}
				},
				{
					path: "invoice_supplier/checked",
					name: "tiersInvoiceSupplierChecked",
					component: () => import("@/components/InvoiceSupplier/TiersChecked"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FOUROCR"
					}
				},
				{
					path: "invoice_supplier/payment",
					name: "tiersInvoiceSupplierPayment",
					component: () => import("@/components/InvoiceSupplier/TiersPayment"),
					props: true,
					meta: {
						onlineOnly: true,
						accessRights: "FOUROCR"
					}
				},
				{
					path: "documents/",
					name: "tiersDocuments",
					component: () => import("@/components/Tiers/Documents"),
					props: true
				},
				{
					path: "courrier/",
					name: "tiersCourrierPerso",
					component: () => import("@/components/Tiers/Courrier"),
					props: true
				},
				{
					path: "groupes/",
					name: "tiersGroups",
					component: () => import("@/components/Tiers/Groupes"),
					props: true
				},
				{
					path: "quotation/",
					name: "tiersQuotation",
					component: () => import("@/components/Tiers/Devis"),
					props: true,
					meta: {
						accessRights: "DEVIS",
						onlineOnly: true
					},
				},
				{
					path: "tiers_localisation/",
					name: "tiersLocalisation",
					component: () => import("@/components/Tiers/Localisation"),
					props: true
				},
				{
					path: "tiers_notes/",
					name: "tiersFicheNotes",
					component: () => import("GroomyRoot/components/Notes/List"),
					props: true,
					meta: {
						accessRights: "NOTE"
					}
				},
				{
					path: "tiers_admin/",
					name: "tiersAdmin",
					component: () => import("@/components/Tiers/Admin"),
					props: true,
					meta: {
						accessRights: "ADMN"
					}
				}
			]
		},
		/* ESPACE CLIENT */
		{
			path: "/acte_type_client_area",
			name: "acteTypeClientArea",
			component: () => import("@/views/ActeTypeClientArea"),
			meta: {
				accessRights: "DUCA",
				onlineOnly: true,
			}
		},
		{
			path: "/acte_liste_notifications/",
			name: "acteListeNotificationsClientArea",
			component: () => import("@/views/ActeListeNotificationsClientArea"),
			meta: {
				accessRights: "DUCA",
				onlineOnly: true,
			}
		},
		{
			path: "/note_liste_notifications/",
			name: "noteListeNotificationsClientArea",
			component: () => import("@/views/NoteListeNotificationsClientArea"),
			meta: {
				accessRights: "DUCA",
				onlineOnly: true,
			}
		},
		{
			path: "/model_client_area",
			name: "modelClientArea",
			component: () => import("@/components/Model/ModelClientArea"),
			props: true,
			meta: {
				accessRights: "DUCA",
				onlineOnly: true,
			}
		},
		{
        	path: "/client_area/config_model/mod_id=:modelable_id/mod_type=:modelable_type/m_id=:model_id",
			name: "configModelClientArea",
        	component: () => import("@/components/Model/EditModelClientArea"),
        	meta: {
				accessRights: "DUCA",
				onlineOnly: true
			},
		},
		{
        	path: "/client_area/horses_management",
			name: "horsesManagementCa",
        	component: () => import("@/views/HorseUnacknowledgedClientArea"),
        	meta: {
				accessRights: "DUCA",
				onlineOnly: true
			},
		},
		{
        	path: "/client_area/contract_options",
			name: "contractCaOptions",
        	component: () => import("@/views/ContractOptionsClientArea"),
			redirect: { name: 'autoinvoiceCaContract' },
        	meta: {
				accessRights: "DUCA",
				onlineOnly: true
			},
			children: [
				{
					path: "auto_invoice/",
					name: "autoinvoiceCaContract",
					component: () => import("@/components/Settings/ClientArea/AutoInvoice"),
					props: true
				},
				{
					path: "cgu_cgv_conditions/",
					name: "cguCgvCaConditions",
					component: () => import("@/components/Settings/ClientArea/CguCgv"),
					props: true
				},
			]
		},
		{
			path: "/notes",
			name: "notes",
			component: () => import("@/views/NotesListe"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true
			},
		},
		{
			path: "/notes_management/h_id=:horse_id?/t_id=:tiers_id?/a_id=:acte_id?/n_id=:note_id?/type=:type",
			name: "notesManagement",
			component: () => import("@/views/NoteManagement"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true
			},
		},

		/* PARAMETRES */
		{
			path: "/ajout_categorie_cheval",
			name: "ajoutCategorieCheval",
			component: () => import("@/views/AjoutCategorieCheval"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			}
		},
		{
			path: "/categories",
			name: "categories",
			component: () => import("@/views/Categories"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false
			},
			children: [
				{
					path: "horse/",
					name: "categoriesHorse",
					component: () => import("@/components/Settings/CategoriesHorse"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "note/",
					name: "categoriesNote",
					component: () => import("@/components/Settings/CategoriesNote"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
			]
		},
		{
			path: "/saison",
			name: "season",
			component: () => import("@/views/Season"),
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/saison/:season_id",
			name: "seasonFiche",
			component: () => import("@/views/SeasonFiche"),
			meta: {
				accessRights: "REPRO|SAILL",
				onlineOnly: true,
			},
			children: [
				{
					path: "information/",
					name: "seasonInformation",
					component: () => import("@/components/Saison/Information"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "planning_monte/",
					name: "seasonPlanningMonte",
					component: () => import("@/components/Saison/PlanningMonte"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "planning_ia/",
					name: "seasonPlanningIA",
					component: () => import("@/components/Saison/PlanningIA"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "modele/",
					name: "seasonModeleContract",
					component: () => import("@/components/Saison/ModeleList"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "contract/config/",
					name: "seasonContractConfig",
					component: () => import("@/components/Saison/ContractConfigGlobal"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "conditions/",
					name: "seasonConditionsContract",
					component: () => import("@/components/Saison/ConditionsList"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "modele/:model_id/distribute/",
					name: "distributeSeasonModeleContract",
					component: () => import("@/components/Saison/ModeleDistribute"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "conditions/:contractconfigglobaltypemonte_id/distribute",
					name: "distributeConditionsContract",
					component: () => import("@/components/Saison/ConditionsDistribute"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				}
			]
		},
		{
			path: "/statistiques",
			name: "statistiques",
			component: () => import("@/views/Statistiques"),
			meta: {
				accessRights: "ADMN",
				onlineOnly: true,
			}
		},
		{
			path: "/licence_management",
			name: "licenceManagement",
			component: () => import("@/views/LicenceManagement"),
			meta: {
				accessRights: "ADMN",
				onlineOnly: true,
			}
		},
		{
			path: "/acte_type_liste",
			name: "acteTypeListe",
			component: () => import("@/views/ActeTypeManagement"),
			meta: {
				accessRights: "ACTYPE"
			}
		},
		{
			path: "/acte_type_ajout/a_id=:acte_type_id?/f=:from?",
			name: "acteTypeAjout",
			component: () => import("@/views/ActeTypeAjout"),
			meta: {
				accessRights: "ACTYPE",
				canDisplayNavMobile: false,
				onlineOnly: true,
			}
		},
		{
			path: "/table_filters_config/tbc_id=:config_id?/tb_c=:table_cle?/tb_f=:from?/sl_id=:share_id?",
			name: "tableConfigFilter",
			component: () => import("@/views/TableConfigFilterV2"),
			meta: {
				accessRights: "CTABLE",
				canDisplayNavMobile: false,
				onlineOnly: true,
			}
		},
		{
			path: "/tab_config/type=:type?/from=:from?",
			name: "tabConfig",
			component: () => import("@/views/TabConfig"),
			meta: {
				accessRights: "MVP",
				canDisplayNavMobile: false,
				onlineOnly: true,
			}
		},
		{
			path: "/export_config",
			name: "exportConfig",
			component: () => import("@/views/ExportConfigDefault"),
			meta: {
				accessRights: "ADMN",
				onlineOnly: true,
			}
		},
        {
            path: "/storage_management",
            name: "storageManagement",
            component: () => import("@/views/StorageManagement")
        },
        {
            path: "/support",
            name: "support",
            component: () => import("@/views/Support")
        },
        {
            path: "/resiliation",
            name: "resiliation",
            component: () => import("@/views/ResiliationForm")
        },
        {
            path: "/advanced_settings",
            name: "advancedSettings",
            component: () => import("@/views/AdvancedSettings"),
            meta: {
            	accessRights: "LICADM",
            	onlineOnly: true
            },
			children: [
				{
					path: "application_tierce/",
					name: "applicationTierce",
					component: () => import("@/components/ApplicationTierce"),
					props: true
				},
				{
					path: "config/",
					name: "configSettings",
					component: () => import("@/components/ConfigSettings"),
					props: true
				},
				{
					path: "api_config/",
					name: "APIConfigSettings",
					component: () => import("@/components/APIConfigSettings"),
					props: true
				},
				{
		        	path: '/web_content_type',
		        	name: 'webContentType',
		        	component: () => import('@/components/WebContentType'),
					props: true,
					meta: {
						accessRights: "COMWEB"
					}
		        }
			]
        },
		{
			path: "/rapprochement_facture",
			name: "rapprochementFacture",
			component: () => import("@/views/RapprochementFacture"),
			meta: {
				accessRights: "ADMN",
				onlineOnly: true,
			}
		},
        {
            path: "/import",
            name: "import",
            component: () => import("@/views/Import"),
            meta: {
				accessRights: "OHARAS",
				onlineOnly: true,
			}
		},
		{
            path: "/manage_stallion_number",
            name: "manageStallionNumber",
            component: () => import("@/views/ManageStallionNumber"),
            meta: {
				accessRights: "ADMN",
				onlineOnly: true,
			}
		},
		{
			path: "/triggers",
			name: "triggers",
			component: () => import("@/views/Triggers"),
			meta: {
				accessRights: "TRIGG",
				recurAccessRights: true
			},
			children: [
				{
					path: "list/",
					name: "triggersList",
					component: () => import("@/components/Triggers/List"),
					props: true
				},
				{
					path: "edit/:triggerentity_id",
					name: "triggersEdit",
					component: () => import("@/components/Triggers/AddTrigger"),
					meta: {
						canDisplayNavMobile: false
					},
					props: true
				},
				{
					path: "add/",
					name: "triggersAdd",
					component: () => import("@/components/Triggers/AddTrigger"),
					meta: {
						canDisplayNavMobile: false
					},
					props: true
				},
			]
		},
		{
			path: "/user_rights_management",
			name: "userRightsManagement",
			component: () => import("@/views/UserRightsManagement"),
			meta: {
				accessRights: "LICADM",
				recurAccessRights: true
			}
		},
        {
        	path: '/localisation',
        	name: 'localisation',
        	component: () => import('@/views/Localisation'),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "gestion",
					name: "gestionLocalisation",
					component: () => import("@/components/Localisation/Localisation")
				},
				{
					path: "cartographie",
					name: "cartographie",
					component: () => import("@/components/Localisation/Cartographie")
				}
			]
		},
		{
        	path: '/intra_location',
        	name: 'intraLocation',
        	component: () => import('@/views/IntraLocation'),
			meta: {
				accessRights: "MVP"
			}
		},
		{
        	path: '/manage_global_locations',
        	name: 'manageGlobalLocations',
        	component: () => import('@/views/ManageGlobalLocations'),
			meta: {
				accessRights: "MVP"
			}
        },
		{
        	path: '/raison_sociale_config',
        	name: 'raisonSocialeConfig',
        	component: () => import('@/views/RaisonSocialeConfig'),
			meta: {
				accessRights: "TIERS|FACT"
			}
        },
		{
        	path: '/emails',
        	name: 'emailsMandrill',
        	component: () => import('@/views/EmailsMandrill'),
			meta: {
				accessRights: "FACT"
			}
        },


        /* CONTRAT DE SAIILIE */
        {
        	path: "/monte/contractconfig_id=:contractconfig_id",
        	name: "ContractConfigForm",
        	component: () => import("@/components/Contract/ContractConfigForm"),
        	props: true,
        	meta: {
        		accessRights: "SAILL"
			}
		},
		{
        	path: "/config_model/mod_id=:modelable_id/mod_type=:modelable_type/m_id=:model_id",
			name: "configModel",
        	component: () => import("@/views/EditModelContract"),
        	meta: {
        		accessRights: "SAILL"
			},
		},
		/* REPRODUCTION */
		{
			path: '/reproduction/embryon',
			name: 'embryonManagement',
			component: () => import('@/views/EmbryonManagement'),
			redirect: { name: 'embryonAvailable' },
			children: [
				{
					path: 'available',
					name: 'embryonAvailable',
					component: () => import('@/components/Gynecologie/EmbryonAvailable'),
				},
				{
					path: 'season',
					name: 'embryonSeason',
					component: () => import('@/components/Gynecologie/EmbryonSeason'),
				}
			]
		},
        /*MONTE*/
        {
        	path: '/monte/contrats_old',
        	name: 'ContractList',
        	component: () => import('@/components/Contract/ContractList'),
			meta: {
				accessRights: "SAILL"
			}
        },
        {
        	path: "/monte/contrats_old/contract_id=:contract_id/stallion_id=:stallion_id?",
        	name: "ContractForm",
        	component: () => import("@/components/Contract/ContractList"),
        	props:true,
        	meta: {
				accessRights: "SAILL"
			}
        },
		{
			path: '/monte/contrats',
			name: 'ContractListv2',
        	component: () => import('@/components/Contract'),
			meta: {
				accessRights: "SAILL"
			}
        },
        {
        	path: "/monte/contrats/contract_id=:contract_id/stallion_id=:stallion_id?/tier_id=:tier_id?/mare_id=:mare_id?",
        	name: "ContractFormv2",
        	component: () => import("@/components/Contract/ContractListv2"),
        	props:true,
        	meta: {
				accessRights: "SAILL"
			}
        },
        {
        	path: '/planning_monte_old',
        	name: 'PlanningMonte',
        	component: () => import('@/views/PlanningMonte'),
			meta: {
				accessRights: "SAILL"
			},
			children: [
				{
					path: "planning_main",
					name: "planningMain",
					component: () => import("@/components/Monte/PlanningMonteMain")
				},
				{
					path: "planning_ia",
					name: "planningIA",
					component: () => import("@/components/Monte/PlanningMonteIA")
				}
			]
        },
        {
        	path: '/planning_monte',
        	name: 'PlanningMonteBeta',
        	component: () => import('@/views/PlanningMonteBeta'),
			meta: {
				accessRights: "SAILL"
			},
			children: [
				{
					path: "planning_main",
					name: "planningMainBeta",
					component: () => import("@/components/Monte/PlanningMonteMainBeta"),
					props: true
				},
				{
					path: "planning_ia",
					name: "planningIABeta",
					component: () => import("@/components/Monte/PlanningMonteIABeta"),
					props: true
				}
			]
        },
		{
        	path: "/monte/expeditions",
        	name: "monteReservation",
        	component: () => import("@/views/MonteReservation"),
        	meta: {
				accessRights: "SAILL"
			}
        },
		{
        	path: "/monte/stock_distant",
        	name: "stockDistant",
        	component: () => import("@/views/StockDistant"),
        	meta: {
				accessRights: "SEMENCE"
			}
        },
		{
        	path: "/monte/stock_local",
        	name: "stockLocal",
        	component: () => import("@/views/StockLocal"),
        	meta: {
				accessRights: "SEMENCE"
			},
			children: [
				{
					path: "visualisation",
					name: "visualisationStock",
					component: () => import("@/components/Monte/VisualisationStock")
				},
				{
					path: "flux",
					name: "fluxStock",
					component: () => import("@/components/Monte/HistoriqueStock")
				},
				{
					path: "plan_cuve",
					name: "planCuve",
					component: () => import("@/components/Monte/PlanCuve")
				}
			]
        },
		{
        	path: "/monte/contrats/create",
        	name: "CreateContract",
        	component: () => import("@/components/Contract/CreateContract"),
        	meta: {
				accessRights: "SAILL"
			}
        },

		/* PARRAINAGE */
		{
			path: "/parrainage",
			name: "parrainage",
			component: () => import("@/views/Parrainage"),
			meta: {
				accessRights: "MVP",
				onlineOnly: true,
			},
			children: [
				{
					path: "filleul/",
					name: "parrainageFilleul",
					component: () => import("@/components/Parrainage/Filleul"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				},
				{
					path: "historique/",
					name: "parrainageHistorique",
					component: () => import("@/components/Parrainage/Historique"),
					props: true,
					meta: {
						onlineOnly: true,
					}
				}
			]
		},

		/* QUALIFICATION */
		{
			path: "/qualification",
			name: "qualification",
			component: () => import("@/views/Qualification"),
			redirect: {
				name: 'qualificationListe',
				params: {
					qualificationtype_libelle: 'tiers'
				}
			},
			meta: {
				accessRights: "FACT"
			},
			children: [
				{
					path: ":qualificationtype_libelle",
					name: "qualificationListe",
					props: true,
					component: () => import("@/components/Qualification/QualificationListe"),
				}
			]
		},

		/* COURRIER TYPE */
		{
			path: "/courrier_type",
			name: "courrierType",
			component: () => import("@/views/CourrierType"),
			meta: {
				accessRights: "MVP"
			},
		},
		{
			path: "/courrier_type/config/:model_id",
			name: "courrierTypeConfig",
			component: () => import("@/views/EditModel"),
			meta: {
				accessRights: "MVP"
			},
		},
		
		/* Carnet saillie */
		{
			path: "/carnet_saillie",
			name: "carnet_saillie",
			component: () => import("@/views/CarnetSaillie"),
			meta: {
				accessRights: "DPS",
				onlineOnly: true
			}
		},

		/* Sanitaire */
		{
			path: "/sanitaire",
			name: "sanitaire",
			component: () => import("@/views/Sanitaire"),
			meta: {
				accessRights: "SANI",
				onlineOnly: true
			}
		},
		
		/* Import DN AS */
		{
			path: "/import_dn_as",
			name: "import_dn_as",
			component: () => import("@/views/ImportDNAS"),
			meta: {
				accessRights: "DPS",
				onlineOnly: true
			}
		},

		/* Stock de semence */
		{
			path: "/localisation_semence",
			name: "localisationSemence",
			component: () => import("@/views/LocalisationSemence"),
			meta: {
				accessRights: "SEMENCE"
			},
			children: [
				{
					path: "cuves/",
					name: "gestionCuves",
					component: () => import("@/components/Congelation/Cuves"),
				},
				{
					path: "dilueurs/",
					name: "dilueurs",
					component: () => import("@/components/Congelation/Dilueurs"),
					meta: {
						accessRights: "CONGEL"
					},
				}
			]
		},

		{
			path: "/categories_management",
			name: "HorseCategoriesManagement",
			component: () => import("@/components/Horse/HorseCategoriesManagement"),
			meta: {
				accessRights: "MVP"
			}
		},

		/* import de données */
		{
			path: "/import_donnees",
			name: "import_donnees",
			component: () => import("@/views/ImportData"),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "horse/",
					name: "ImportHorse",
					component: () => import("@/components/Import/Horse"),
					props: true
				}
			]
		},

		/* demande d'analyse */
		// {
		// 	path: "/demande_analyse",
		// 	name: "demandeAnalyse",
		// 	component: () => import("@/views/DemandeAnalyse"),
		// 	meta: {
		// 		accessRights: "MVP"
		// 	}
		// },

		{
			path: "/analyse_sanitaire",
			name: "AnalyseSanitaire",
			component: () => import("@/views/AnalyseSanitaire"),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "demande_analyse/",
					name: "DemandeAnalyse",
					component: () => import("@/components/Acte/DemandeAnalyse"),
					meta: {
						accessRights: "MVP"
					}
				},
				{
					path: "resultat_saniaire/",
					name: "ResultatSanitaire",
					component: () => import("@/components/Acte/ResultatSanitaire"),
					meta: {
						accessRights: "MVP"
					}
				},
			]
		},

		{
			path: "/gocardless_rapprochement/:token",
			name: "GocardlessRapprochement",
			component: () => import("@/views/GocardlessRapprochement"),
			meta: {
				noLoggedIn: true,
				onlineOnly: true,
			}
		},

		/* AJOTU CHEVAL */
		{
			path: "/add_horse",
			name: "addHorse",
			component: () => import("@/views/HorseAjoutGlobal"),
			meta: {
				accessRights: "MVP",


			},
			children: [
				{
					path: "search_sire/:salon?",
					name: "SearchSire",
					component: () => import("@/components/Horse/SearchSire"),
					props: true
				},
				{
					path: "microship",
					name: "Microship",
					component: () => import("@/components/Horse/Ajout/Microship"),
					props: true
				},
				{
					path: "lot_sire",
					name: "LotSire",
					component: () => import("@/components/Horse/Ajout/LotSire"),
					props: true
				},
				{
					path: "add_manuel",
					name: "AddManuel",
					component : () => import("@/components/Horse/Ajout/AjoutManuel"),
					props: true
				},
				{
					path: "search_weatherbys/",
					name: "SearchWeatherbys",
					component: () => import("@/components/Horse/SearchWeatherbys"),
					props: true
				},
				{
					path: "post_import_horse_list/",
					name: "postImportHorseList",
					component: () => import("@/components/Horse/PostImportHorseList"),
					props: true
				},
			]
		},

		/* DEVIS */
		{
			path: "/quotation",
			name: "quotation",
			component: () => import("@/views/Devis"),
			meta: {
				accessRights: "DEVIS",
				onlineOnly: true
			}
		},
        {
        	path: "/quotation/:quotation_id",
        	name: "EditDevis",
        	component: () => import("@/views/EditDevis"),
        	props: true,
        	meta: {
				accessRights: "DEVIS"
			}
        },
        {
        	path: "/quotation/:quotation_id/document",
        	name: "DevisDocument",
        	component: () => import("@/views/DevisDocument"),
        	props: true,
        	meta: {
				accessRights: "DEVIS"
			}
        },
		/* RECHERCHE AVANCEE */
		{
			path: "/advanced_search/:search_value",
			name: "AdvancedSearch",
			component: () => import("@/components/AdvancedSearch"),
			props: true,
			meta: {
				accessRights: "MVP"
			}
		},
		/* RACCOURCIS */
		{
			path: "/shortcuts",
			name: "Shortcuts",
			component: () => import("@/views/Shortcuts"),
			props: true,
			meta: {
				accessRights: "MVP"
			}
		},
		/* VEHICULE */
		{
			path: "/vehicule",
			name: "Vehicule",
			component: () => import("@/views/VehiculeManagement"),
			props: true,
			meta: {
				accessRights: "MVP"
			}
		},
		/* DEPLACEMENT */
		{
			path: "/deplacement",
			name: "Deplacement",
			component: () => import("@/views/Deplacement"),
			props: true,
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "list",
					name: "DeplacementListe",
					component: () => import("@/components/Mouvements/Deplacement"),
					props: true
				},
				{
					path: "suggest",
					name: "DeplacementSuggestion",
					component: () => import("@/components/Mouvements/Suggestion"),
					props: true
				}
			]
		},
		{
			path: "/suggestion_mouvement/",
			name: "SuggestionMouvement",
			component: () => import("@/views/SuggestionMouvement"),
			meta: {
				accessRights: "MVP"
			},
			children: [
				{
					path: "stallion",
					name: "SuggestionMouvementStallion",
					component: () => import("@/components/Mouvements/SuggestionEtalon"),
					props: true
				},
				{
					path: "mare",
					name: "SuggestionMouvementMare",
					component: () => import("@/components/Mouvements/SuggestionMare"),
					props: true
				}
			]
		},
		/* PAX */
		{
			path: "/pax",
			name: "PAX",
			component: () => import("@/views/GestionPax"),
			props: true,
			meta: {
				accessRights: "CAPAX"
			},
			children: [
				{
					path: "traduction",
					name: "PAXTraduction",
					component: () => import("@/components/PAX/Traduction"),
					props: true
				},
				{
					path: "criteres",
					name: "PAXCriteres",
					component: () => import("@/components/PAX/Criteres"),
					props: true,
				}
			]
		},
		/* PLANNING */
		{
			path: "/task_planning",
			name: "TaskPlanning",
			component: () => import("@/views/TaskPlanning"),
			props: true,
			meta: {
				accessRights: "MVP"
			}
		},
		{
			path: "/task_planning/setting",
			name: "TaskPlanningSetting",
			component: () => import("@/views/TaskPlanningSetting"),
			props: true,
			meta: {
				accessRights: "MVP"
			}
		},
	]
}
