import { EventBus } from 'EventBus'

export default {
	data() {
		return {
			need_events: false, // disable Navigation mixin events
		}
	},
    methods: {		
		async initUserPermissions() {
			return this.refreshVueExPermission()
		},

		async refreshVueExPermission() {
            let temp = await this.getUserConfig('permissions')

			// Si compte déjà log avec un db, la synchro required n'est pas forcée au démarrage. On doit passer par le localstorage
			if(!temp) {
				temp = this.getUserAccessRightsThroughLocalstorage()
			}
			this.userPermissions = temp
			this.authorizedHorses = await this.fetchAuthorizedHorses()

			EventBus.$emit('User::AccessRightsReloaded')
			this.initMenuRights()
		},

        getUserAccessRights() {
			return this.userPermissions
		},

		getUserAuthorizedHorses() {
			return this.authorizedHorses
		},

		getUserHorseLimit() {
			const matches = this.userPermissions.match(/LIMIT[0-9]+/g)
			if(!matches || matches.length === 0) return 0

			const limit_code = matches[0]
			const limit_num  = limit_code.substr('LIMIT'.length)
			return parseInt(limit_num)
		},

		fetchAuthorizedHorses() {
            return []
		},
		
        checkUserAccessRights(level_codes) {
			const user_rights = this.getUserAccessRights()
            if(!user_rights) return false
            const levels = level_codes.split('|')
			const user_rights_ls = user_rights.split('-')

			for(let i = 0; i < levels.length; i++) {
				const level = levels[i]
				const level_cond = level.split('&')
				let has_right = true

				for(let j = 0; j < level_cond.length; j++) {
					const levcond = level_cond[j]

					// Le droit du AND n'est pas autorisé, on tente le suivant
					if(user_rights_ls.indexOf(levcond) === -1) {
						has_right = false
						break
					}
				}

				if(has_right) {
					return true
				}
			}

			return false
		},
		
		initMenuRights() {
			let access = this.getUserAccessRights()

			if(!access) return
			access = access.split('-')

			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasMvpAccess', val: access.indexOf("MVP") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasEmailVerified', val: access.indexOf("VRFD") > -1 })

			// Menus "Factures fournisseurs" et "Paiements fournisseurs", passant à true si un des clients groomy, ayant l'option facture fournisseur, possède un tiers à la même adresse mail
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasInvoiceSupplier', val: access.indexOf("IFOURN") > -1 }) 

			// Permissions générales
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasFacturationAccess', val: access.indexOf("FACT") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasEntitiesAccess', val: access.indexOf("TIERS") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasBreedingAccess', val: access.indexOf("REPRO") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasSaillieAccess', val: access.indexOf("SAILL") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasActTypeAccess', val: access.indexOf("ACTYPE") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasTriggerAccess', val: access.indexOf("TRIGG") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasConfTableAccess', val: access.indexOf("CTABLE") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasAdminAccess', val: access.indexOf("ADMN") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasDevAccess', val: access.indexOf("DEVL") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasDedicatedClientArea', val: access.indexOf("DUCA") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasDPSAccess', val: access.indexOf("DPS") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasSanitaireAccess', val: access.indexOf("SANI") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasNoteAccess', val: access.indexOf("NOTE") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasMultiComptaAccess', val: access.indexOf("MULTICOMPTA") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasHubspotAccess', val: access.indexOf("HUBSPOT") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasDevisAccess', val: access.indexOf("DEVIS") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasPaxAccess', val: access.indexOf("CAPAX") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasSemenceAccess', val: access.indexOf("SEMENCE") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasMailTiersAccess', val: access.indexOf("MAILTIERS") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasTrainerAccess', val: access.indexOf("ENTRN") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasWebCommunicationAccess', val: access.indexOf("COMWEB") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasSignatureAccess', val: access.indexOf("SIGNELEC") > -1 })
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasInvoiceSupplierOCR', val: access.indexOf("FOUROCR") > -1 }) 
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasBridgeConnect', val: access.indexOf("BRIDGE") > -1 }) 
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasCongelAccess', val: access.indexOf("CONGEL") > -1 }) 

			// Permissions héritées de la licence
			this.$store.commit({ type: 'updateUserAccess', accessType: 'hasInheritedlicenceFacturationAccess', val: access.indexOf("ALHFACT") > -1 })
        }
	},
	computed: {
		userPermissions: {
			get() {
				return this.$store.state.user.permissions
			},
			set(val) {
				this.$store.commit('user/setPermissions', val)
			}
		},
		hasMultiLicence() {
			return this.$store.state.userAccess.hasMultiActiveLicences
		},
		authorizedHorses: {
			get() {
				return this.$store.state.user.horse_whitelist
			},
			set(val) {
				this.$store.commit('user/setHorseWhitelist', val)
			}
		}
	}
}
