import Transformer from './Transformer'
import AccountingPlanTransformer from './AccountingPlanTransformer'

export default class TiersClientAccountTransformer extends Transformer {

	table = 'tiers_client_account'

	async accounting_plan(tiers_client_account) {
		const accounting_plan = await this.fetchRelationship(tiers_client_account, 'tiersclientaccount_accountingplan')
		return AccountingPlanTransformer.process(accounting_plan)
	}

    transform(tiers_client_account) {
        return {
			'tiersclientaccount_id': tiers_client_account.tiersclientaccount_id,
			'tiersclientaccount_tiers': tiers_client_account.tiersclientaccount_tiers,
        }
	}

	fetchWithTiers(col) {
		this.additionalColumns({
			accounting_plan: this.accounting_plan
		})

		return col
	}

	transformWithTiers(tiers_client_account) {
        return {
			'entity': tiers_client_account.accounting_plan ? tiers_client_account.accounting_plan.tiers : null,
			'tiersclientaccount_id': tiers_client_account.tiersclientaccount_id,
			'tiersclientaccount_tiers': tiers_client_account.tiersclientaccount_tiers,
			'tiersclientaccount_number': tiers_client_account.tiersclientaccount_number,
			'tiersclientaccount_vat_code': tiers_client_account.tiersclientaccount_vat_code,
			'tiersclientaccount_accountingplan': tiers_client_account.accounting_plan,
			'tiersclientaccount_supplier': tiers_client_account.tiersclientaccount_supplier
        }
	}
}